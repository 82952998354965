'use client'
import { renderLayout } from '@/cms_components'
import { ComponentContext } from '@/types/cmsConfig'
import {
    Contact_infoCollection,
    CZ_MenuCollection,
    SneakerLayoutComponent,
} from '@/types/cmsGenerated'
import { Text, TextColorkeys } from '@/components/Text'
import { Divider, DividerColorkeys } from '@/components/Divider'
import React from 'react'
import { Button } from '@/components/Button'

import './Footer.css'
import { useTranslation } from 'react-i18next'

type FooterTypes = {
    footerData: CZ_MenuCollection
    context: ComponentContext
    contactInfoData: Contact_infoCollection
}

const rowLinkClasses =
    'p-0 h-auto min-h-0 no-underline hover:no-underline font-normal text-base leading-none text-white'

const Footer = ({ footerData, contactInfoData }: FooterTypes) => {
    const {
        companyInfoSection,
        firstLinkSectionHeading,
        secondLinkSectionHeading,
        firstLinkSection,
        secondLinkSection,
        leftUnderLineSection,
        rightUnderLineSection,
    } = footerData?.menuItems?.[0]
        ?.settings as SneakerLayoutComponent['settings']
    const { address, shipping_hours, phone, e_mail } = contactInfoData
        ?.layout?.[0]?.settings as any

    const { t } = useTranslation()

    return (
        <footer
            className={
                'bg-blue-dark pt-[106px] pb-[30px]  lg:h-[558px] flex align-bottom'
            }
        >
            <div
                className={
                    'w-full max-w-[1320px] mx-auto px-5 flex flex-col justify-between'
                }
            >
                <div className={'flex flex-col justify-between lg:flex-row'}>
                    <div
                        className={
                            'companyInfoSectionWrapper items-center flex flex-col max-w-[400px] gap-[38px] font-normal mx-auto mb-20 sm:items-start lg:mb-0 lg:ml-0 lg:mr-20'
                        }
                    >
                        {renderLayout(companyInfoSection)}
                    </div>
                    <div
                        className={
                            'flex flex-col w-full justify-between mx-auto max-w-[594px] gap-20 sm:flex-row sm:gap-0 lg:mx-0'
                        }
                    >
                        <div
                            className={
                                'flex flex-col gap-[27px] items-center sm:items-start'
                            }
                        >
                            <Text
                                text={firstLinkSectionHeading}
                                color={TextColorkeys.GRAY_MIDDLE}
                                className={'mb-4'}
                            />
                            {firstLinkSection.map((linkItem, index: number) => (
                                <Button
                                    key={index}
                                    url={linkItem.value.link}
                                    color="link"
                                    rootClasses={rowLinkClasses}
                                >
                                    {linkItem.value.label}
                                </Button>
                            ))}
                        </div>
                        <div
                            className={
                                'flex flex-col gap-[27px] items-center sm:items-start'
                            }
                        >
                            <Text
                                text={secondLinkSectionHeading}
                                color={TextColorkeys.GRAY_MIDDLE}
                                className={'mb-4'}
                            />
                            {secondLinkSection.map(
                                (linkItem, index: number) => (
                                    <Button
                                        key={index}
                                        url={linkItem.value.link}
                                        color="link"
                                        rootClasses={rowLinkClasses}
                                    >
                                        {linkItem.value.label}
                                    </Button>
                                )
                            )}
                        </div>
                        <div
                            className={
                                'flex flex-col items-center sm:items-start sm:max-w-[190px]'
                            }
                        >
                            <Text
                                text={t('footer.contactUs')}
                                color={TextColorkeys.GRAY_MIDDLE}
                                className={'mb-[43px]'}
                            />
                            <Text
                                text={address}
                                color={TextColorkeys.WHITE}
                                className={'mb-[22px]'}
                            />
                            <Text
                                text={phone}
                                color={TextColorkeys.WHITE}
                                className={'mb-1'}
                            />
                            <Text
                                text={e_mail}
                                color={TextColorkeys.WHITE}
                                className={'mb-[22px]'}
                            />
                            <Text
                                text={t('footer.shippingHours')}
                                color={TextColorkeys.WHITE}
                                className={'mb-1'}
                            />
                            <Text
                                text={shipping_hours}
                                color={TextColorkeys.WHITE}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <Divider
                        type={'solid'}
                        width={'thin'}
                        color={DividerColorkeys.BLUE_MEDIUM}
                        className={'my-[30px]'}
                    />
                    <div
                        className={'flex flex-col justify-between md:flex-row'}
                    >
                        <div
                            className={
                                'flex flex-col gap-[26px] items-center md:flex-row'
                            }
                        >
                            {renderLayout(leftUnderLineSection)}
                        </div>
                        <div className={'flex mx-auto mt-10 md:mx-0 md:mt-0'}>
                            {renderLayout(rightUnderLineSection)}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
